import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Slider from 'react-slick';
import slickStyles from 'slick-carousel/slick/slick.css';
import slickStylesTheme from 'slick-carousel/slick/slick-theme.css';
import { withCookies, Cookies } from 'react-cookie';
import _ from 'lodash';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import LinearProgress from '@material-ui/core/LinearProgress';
import ExpandMore from '@material-ui/icons/ExpandMore';

import CourseItem from '../../components/CourseItem/CourseItem';
import LessonItem from '../../components/LessonItem/LessonItem';
import Link from '../../components/Link';
import CircularProgressLoader from '../../components/CircularProgressLoader/CircularProgressLoader';
import {
  isEditor,
  isTourShown,
  isTrial,
  buildQuery,
  parseSort,
  getContentCategoriesForFilter,
} from '../../helpers';
import isLoggedIn from '../../helpers/checkAuth';

import history from '../../history';

import Arrow from './Arrow';
import ContinueLearning from './ContinueLearning';
import Filter from './Filter';
import DashboardDiscussions from './DashboardDiscussions';
import initialDefaultState from './intialState';
import { messages as tourMessages } from './Tour';
import Tour from '../../components/Tour';

import * as coursesActions from '../../actions/courses';
import * as lessonsActions from '../../actions/homeLessons';
import * as searchActions from '../../actions/search';
import * as tourActions from '../../actions/tours';
import * as authActions from '../../actions/auth';
import * as disscussionsActions from '../../actions/discussions';
import * as followedDisscussionsActions from '../../actions/dashboardDiscussions';

import s from './Courses.scss';

import messages from './coursesMessges';

import SkholeIcon41 from '../../components/statelessComponents/Icons/SkholeIcon41';
import SkholeIcon40 from '../../components/statelessComponents/Icons/SkholeIcon40';
import SkholeIcon17BiggerSize from '../../components/statelessComponents/Icons/SkholeIcon17BiggerSize';
import * as contentCategoriesActions from '../../actions/contentCategories';
import * as tagsActions from '../../actions/tags';
import CircularProgressSmall from '../../components/CircularProgressSmall';
import { COURSE_TYPES_FILTER_VALUES } from '../../constants';

@injectIntl
@withCookies
@withStyles(slickStyles)
@withStyles(slickStylesTheme)
@withStyles(s)
@connect(
  state => ({
    loadingCourses: state.courses.loading,
    loadedCourses: state.courses.loaded,
    courses: state.courses.courses,
    totalCourses: state.courses.total,
    loadingLessons: state.homeLessons.loading,
    loadedLessons: state.homeLessons.loaded,
    lessons: state.homeLessons.lessons,
    totalLessons: state.homeLessons.total,
    auth: state.auth,
    favoriteCourses: state.auth.favoriteCourses,
    tags: state.tags.tags,
    loadingTags: state.tags.loading,
    isLoadedTags: state.tags.loaded,
    pendingTags: state.search.pendingTags,
    selectedTags: state.search.tags,
    wereTagsEmpty: state.tags.wereEmpty,
    contentCategories: state.contentCategories.contentCategories,
    loadingContentCategories: state.contentCategories.loading,
    isLoadedContentCategories: state.contentCategories.loaded,
    pendingContentCategories: state.search.pendingContentCategories,
    search: state.search.search,
    selectedContentCategories: state.search.contentCategories,
    lang: state.intl.locale,
    dashboardData: state.auth.dashboardData,
    loadingDashboardData: state.auth.loadingDashboardData,
    loadedDashboardData: state.auth.loadedDashboardData,
    notifications: state.notifications.notifications,
    discussions: state.discussions.discussions,
    loadedDiscussions: state.discussions.loaded,
    loadingDiscussions: state.discussions.loading,
    followedDiscussions: state.followedDiscussions.followedDiscussions,
    loadedFollowedDiscussions: state.followedDiscussions.loaded,
    loadingFollowedDiscussions: state.followedDiscussions.loading,
    isNotificationsMenuOpened: state.search.isNotificationsMenuOpened,
    isTourOpen: isTourShown(state, 'courses'),
    searchSessionId: state.search.searchSession._id,
  }),
  dispatch =>
    bindActionCreators(
      {
        getCourses: coursesActions.getCourses,
        resetCourses: coursesActions.reset,
        ...lessonsActions,
        ...followedDisscussionsActions,
        loadAuth: authActions.loadAuth,
        getDiscussions: disscussionsActions.getDiscussions,
        resetDiscussions: disscussionsActions.reset,
        saveFavoriteCourses: authActions.saveFavoriteCourses,
        getDashboardData: authActions.getDashboardData,
        resetDashboardData: authActions.resetDashboardData,
        toggleNotificationsPopover: searchActions.toggleNotificationsPopover,
        toggleTour: tourActions.toggleTour,
        getContentCategories: contentCategoriesActions.getContentCategories,
        resetContentCategories: contentCategoriesActions.reset,
        getTags: tagsActions.getTags,
        resetTags: tagsActions.reset,
        ...searchActions,
      },
      dispatch,
    ),
)
@connect()
export default class Courses extends React.Component {
  static propTypes = {
    loadingCourses: PropTypes.bool.isRequired,
    loadedCourses: PropTypes.bool.isRequired,
    totalCourses: PropTypes.number.isRequired,
    courses: PropTypes.array.isRequired, // eslint-disable-line
    loadingLessons: PropTypes.bool.isRequired,
    loadedLessons: PropTypes.bool.isRequired,
    totalLessons: PropTypes.number.isRequired,
    lessons: PropTypes.array.isRequired, // eslint-disable-line
    auth: PropTypes.shape({
      user: PropTypes.shape({}),
    }).isRequired,
    getCourses: PropTypes.func.isRequired,
    getLessons: PropTypes.func.isRequired,
    cookies: PropTypes.instanceOf(Cookies).isRequired,

    resetTags: PropTypes.func.isRequired,
    selectedTags: PropTypes.arrayOf(PropTypes.string).isRequired,
    tags: PropTypes.array.isRequired, // eslint-disable-line
    loadingTags: PropTypes.bool.isRequired,
    wereTagsEmpty: PropTypes.bool.isRequired,
    isLoadedTags: PropTypes.bool.isRequired,
    pendingTags: PropTypes.array.isRequired, // eslint-disable-line
    changePendingTagsField: PropTypes.func.isRequired,
    changeTagsField: PropTypes.func.isRequired,
    getTags: PropTypes.func.isRequired,

    resetContentCategories: PropTypes.func.isRequired,
    resetCourses: PropTypes.func.isRequired,
    resetLessons: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,
    selectedContentCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
    contentCategories: PropTypes.array.isRequired, // eslint-disable-line
    loadingContentCategories: PropTypes.bool.isRequired,
    isLoadedContentCategories: PropTypes.bool.isRequired,
    pendingContentCategories: PropTypes.array.isRequired, // eslint-disable-line
    changePendingContentCategoriesField: PropTypes.func.isRequired,
    changeContentCategoriesField: PropTypes.func.isRequired,
    getContentCategories: PropTypes.func.isRequired,
    discussions: PropTypes.array.isRequired, // eslint-disable-line
    loadedDiscussions: PropTypes.bool.isRequired,
    loadingDiscussions: PropTypes.bool.isRequired,
    followedDiscussions: PropTypes.array.isRequired, // eslint-disable-line
    loadedFollowedDiscussions: PropTypes.bool.isRequired,
    loadingFollowedDiscussions: PropTypes.bool.isRequired,
    notifications: PropTypes.array.isRequired, // eslint-disable-line
    saveFavoriteCourses: PropTypes.func.isRequired,
    getDashboardData: PropTypes.func.isRequired,
    resetDashboardData: PropTypes.func.isRequired,
    getFollowedDiscussions: PropTypes.func.isRequired,
    resetFollowedDiscussion: PropTypes.func.isRequired,
    getDiscussions: PropTypes.func.isRequired,
    resetDiscussions: PropTypes.func.isRequired,
    toggleNotificationsPopover: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    lang: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    isTourOpen: PropTypes.bool.isRequired,
    toggleTour: PropTypes.func.isRequired,
    isNotificationsMenuOpened: PropTypes.bool.isRequired,
    page: PropTypes.number,
    dashboardData: PropTypes.shape({
      quizResult: PropTypes.shape({}),
    }).isRequired,
    loadingDashboardData: PropTypes.bool.isRequired,
    loadedDashboardData: PropTypes.bool.isRequired,
    loadAuth: PropTypes.func.isRequired,
    changeSearchSession: PropTypes.func.isRequired,
    listMode: PropTypes.bool,
    searchSessionId: PropTypes.string,
  };

  static defaultProps = {
    page: undefined,
    listMode: false,
    searchSessionId: '',
  };

  static contextTypes = {
    fetch: PropTypes.func,
    apiUrl: PropTypes.string,
    query: PropTypes.shape({
      filter: PropTypes.string,
      page: PropTypes.string,
      sort: PropTypes.string,
      search: PropTypes.string,
      contentCategories: PropTypes.arrayOf(PropTypes.string),
      contentLangId: PropTypes.string,
      type: PropTypes.string,
    }),
    headerRef: PropTypes.node,
    togglePwa: PropTypes.func,
    cookiePrefix: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    contentLangs: PropTypes.arrayOf(
      PropTypes.shape({
        createDate: PropTypes.string,
        lang: PropTypes.string,
        name: PropTypes.string,
        _id: PropTypes.string,
      }),
    ).isRequired,
  };

  sliderSettings = {
    dots: false,
    infinite: true,
    arrows: true,
    accessibility: false,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  initialState = {
    ...initialDefaultState,
  };

  constructor(props, context) {
    super(props, context);
    const initialState = {
      ...(this.initialState || {}),
      contentLangId: context.contentLangs?.find(
        item => item.lang === this.props.intl.locale,
      )?._id,
      contentCategories: getContentCategoriesForFilter(props.auth) || [],
    };
    this.initialState = initialState;

    this.state = {
      perPage: 32,
      coursesPage: _.get(props, 'page', 1),
      filter: _.get(context, 'query.filter', this.initialState.filter),
      sort: _.get(context, 'query.sort', this.initialState.sort),
      ...parseSort(_.get(context, 'query.sort', this.initialState.sort)),
      lessonsPage: 1,
      isDashboardExpanded:
        this.props.cookies.get(`${context.cookiePrefix}isDashboardShown`) ||
        false,
      isIndexExpanded: false,
      isTagsIndexExpanded: false,
      isFiltersMobileExpanded: false,
      listMode: this.props.listMode,
      organizationCourses: _.get(props, 'courses', []),
      contentLangId: _.get(
        context,
        'query.contentLangId',
        this.initialState.contentLangId,
      ),
      type: _.get(context, 'query.type', this.initialState.type),
    };
    this.updateHistoryQueryParamsDebounced = _.debounce(
      this.updateHistoryQueryParams,
      800,
    );
  }

  componentDidMount() {
    const {
      getDashboardData,
      getDiscussions,
      getFollowedDiscussions,
      auth,
      getContentCategories,
      changePendingContentCategoriesField,
      getTags,
      changePendingTagsField,
    } = this.props;
    changePendingTagsField(this.props.selectedTags);
    changePendingContentCategoriesField(this.props.selectedContentCategories);
    window.addEventListener('scroll', this.handleOnScroll);

    if (isLoggedIn(auth)) {
      getDashboardData({});

      getDiscussions({
        page: 1,
        perPage: 3,
        sort: 'createDate',
      });

      getFollowedDiscussions({
        followed: true,
        page: 1,
        perPage: 3,
        sort: 'createDate',
      });

      if (getContentCategoriesForFilter(auth)) {
        getContentCategories({
          page: 1,
          perPage: 50,
          sort: `name.${this.getCurrentContentLang()?.lang}`,
          search: '',
          isGeneral: false,
        });
        this.props.dispatch(
          searchActions.setIsInitialContentCategoriesLoad(false),
        );
      }
    }

    if (_.get(this.context, 'headerRef.logoBtnRef')) {
      this.context.headerRef.logoBtnRef.link.addEventListener(
        'click',
        this.clearFilter,
      );
    }

    getTags({
      page: 1,
      perPage: 1000,
      sort: 'name',
      contentLangId: this.getCurrentContentLang()?._id,
      isHomePageSearch: true,
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      !_.get(this.context, 'headerRef.logoBtnRef') &&
      _.get(nextContext, 'headerRef.logoBtnRef')
    ) {
      nextContext.headerRef.logoBtnRef.link.addEventListener(
        'click',
        this.clearFilter,
      );
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      _.get(this, 'props.auth.user._id') &&
      _.get(this, 'props.auth.user._id') !== _.get(prevProps, 'auth.user._id')
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          filter: '',
          type: '',
          sort: '-createDate',
          ...parseSort('-createDate'),
        },
        this.updateHistoryQueryParams,
      );
      return;
    }

    const filledSearch = _.trim(prevProps.search).length > 2;
    const nextFilledSearch = _.trim(this.props.search).length > 2;

    if (
      this.props.search !== prevProps.search &&
      ((!filledSearch && nextFilledSearch) ||
        (filledSearch && nextFilledSearch) ||
        (filledSearch && !nextFilledSearch))
    ) {
      if (nextFilledSearch && this.state.isDashboardExpanded) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          isDashboardExpanded: false,
        });
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        lessonsPage: 1,
        coursesPage: 1,
      });
      this.updateHistoryQueryParamsDebounced();
      return;
    }

    if (
      !_.isEqual(
        this.props.selectedContentCategories,
        prevProps.selectedContentCategories,
      ) ||
      !_.isEqual(this.props.selectedTags, prevProps.selectedTags)
    ) {
      this.updateHistoryQueryParams();
    }
  }

  componentWillUnmount() {
    const {
      resetTags,
      resetContentCategories,
      resetCourses,
      resetLessons,
      resetDashboardData,
      dispatch,
      searchSessionId,
      search,
    } = this.props;
    window.removeEventListener('scroll', this.handleOnScroll);

    if (_.get(this, 'context.headerRef.logoBtnRef.link')) {
      this.context.headerRef.logoBtnRef.link.removeEventListener(
        'click',
        this.clearFilter,
      );
    }

    dispatch(searchActions.changeSearchSession(searchSessionId, search));
    dispatch(searchActions.changeSearchField(''));

    resetTags();
    resetContentCategories();
    resetCourses();
    resetDashboardData();
    resetLessons();
    this.props.dispatch(searchActions.setIsInitialContentCategoriesLoad(true));
  }

  getCurrentContentLang = () =>
    this.state.contentLangId === 'all'
      ? undefined
      : this.context.contentLangs?.find(
          item => item._id === this.state.contentLangId,
        );

  onFilterChange = event => {
    let filter = event.target.value;
    if (filter === 'all') {
      filter = '';
    }
    const { filter: oldFilter } = this.state;

    if (filter === oldFilter) {
      return;
    }

    this.setState(
      state => ({
        ...state,
        lessonsPage: 1,
        coursesPage: 1,
        filter,
      }),
      this.updateHistoryQueryParams,
    );
  };

  onContentLanguageChange = event => {
    const contentLangId = event.target.value;

    const { contentLangId: oldContentLangId } = this.state;

    if (contentLangId === oldContentLangId) {
      return;
    }

    this.props.resetTags();
    this.props.dispatch(searchActions.changePendingTagsField([]));
    this.props.dispatch(searchActions.changeTagsField([]));
    this.props.getTags({
      page: 1,
      perPage: 1000,
      sort: 'name',
      contentLangId:
        contentLangId === 'all'
          ? undefined
          : this.context.contentLangs?.find(item => item._id === contentLangId)
              ?._id,
      isHomePageSearch: true,
    });
    this.props.changeTagsField([]);

    this.setState(
      state => ({
        ...state,
        lessonsPage: 1,
        coursesPage: 1,
        contentLangId,
      }),
      this.updateHistoryQueryParams,
    );
  };

  onContentTypeChange = event => {
    let type = event.target.value;
    if (type === 'all') {
      type = '';
    }
    const { type: oldContentType } = this.state;

    if (type === oldContentType) {
      return;
    }

    this.setState(
      state => ({
        ...state,
        lessonsPage: 1,
        coursesPage: 1,
        type,
      }),
      this.updateHistoryQueryParams,
    );
  };

  getCurrentQueryParams = fieldToOmit => {
    const {
      filter,
      sort,
      perPage,
      coursesPage,
      contentLangId,
      type,
    } = this.state;

    const {
      tags,
      selectedTags,
      search,
      selectedContentCategories,
      contentCategories,
      listMode,
    } = this.props;

    const params = {
      sort,
      page: coursesPage,
      search: _.trim(search).length < 3 ? '' : _.trim(search),
      perPage,
      filter,
      contentLangId,
      type,
    };

    if (listMode) {
      params.listMode = true;
    }

    if (
      selectedTags &&
      selectedTags.length &&
      (tags.length !== selectedTags.length || tags.length === 1)
    ) {
      params.tags = selectedTags;
    }

    if (
      selectedContentCategories &&
      selectedContentCategories.length &&
      (contentCategories.length !== selectedContentCategories.length ||
        contentCategories.length === 1)
    ) {
      params.contentCategories = selectedContentCategories;
    }

    _.forIn(params, (value, key) => {
      if (!value) {
        delete params[key];
      }
    });

    return _.omit(params, fieldToOmit);
  };

  setIsColumnDisplayMode = async listMode => {
    if (isLoggedIn(this.props.auth)) {
      await this.props.dispatch(authActions.toggleListMode(listMode));
    }

    this.props.resetCourses();
    this.props.resetLessons();
    this.setState({ listMode });

    this.updateHistoryQueryParams({ listMode });
  };

  loadMoreCourses = () => {
    const { loadingCourses } = this.props;
    const { coursesPage } = this.state;
    if (!this.hasMoreCourses() || loadingCourses) return;
    this.setState({ coursesPage: coursesPage + 1 }, this.loadCourses);
  };

  loadMoreLessons = () => {
    const { loadingLessons } = this.props;
    const { lessonsPage } = this.state;
    if (!this.hasMoreLessons() || loadingLessons) return;
    this.setState({ lessonsPage: lessonsPage + 1 }, this.loadLessons);
  };

  loadCourses = (queryParams = {}, doNotAppend = false) => {
    const { getCourses } = this.props;
    const currentQueryParams = this.getCurrentQueryParams();
    if (
      currentQueryParams.contentLangId === 'all' ||
      currentQueryParams.search
    ) {
      currentQueryParams.contentLangId = undefined;
    }

    if (currentQueryParams.search) {
      currentQueryParams.tags = [];
      currentQueryParams.contentCategories = [];
      currentQueryParams.sort = '';
    }

    getCourses(
      _.assign({ includeNotAvailable: true }, currentQueryParams, queryParams),
      doNotAppend,
    );
  };

  loadLessons = (queryParams = {}, doNotAppend = false) => {
    const { getLessons, search } = this.props;

    if (!search) {
      return;
    }

    const currentQueryParams = this.getCurrentQueryParams([
      'filter',
      'type',
      'tags',
    ]);
    if (currentQueryParams.contentLangId === 'all' || search) {
      currentQueryParams.contentLangId = undefined;
    }

    const { lessonsPage } = this.state;
    const params = {
      ...currentQueryParams,
      page: lessonsPage,
      isHomePage: true,
      withoutPopulation: true,
      includeNotAvailable: true,
    };

    getLessons(_.assign({}, queryParams, params), doNotAppend);
  };

  hasMoreCourses = () => {
    const { totalCourses } = this.props;
    const { perPage, coursesPage } = this.state;

    return Math.ceil(totalCourses / perPage) > coursesPage;
  };

  hasMoreLessons = () => {
    const { totalLessons } = this.props;
    const { perPage, lessonsPage } = this.state;

    return Math.ceil(totalLessons / perPage) > lessonsPage;
  };

  handleOnScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    const clientHeight =
      document.documentElement.clientHeight || window.innerHeight;

    const scrolledToBottom =
      Math.ceil(scrollTop + clientHeight) >= scrollHeight - 0.3 * scrollHeight;

    const filledSearch = _.trim(this.props.search).length > 2;

    if (scrolledToBottom && !filledSearch) {
      this.loadMoreCourses();
    }
  };

  changeSort = async ({ target: { value } }) => {
    const orderBy = value;
    const { order } = this.state;

    const sort = _.map(
      orderBy.split(','),
      item => `${order === 'desc' ? '-' : ''}${item}`,
    ).join(',');

    this.setState(
      { orderBy, sort, coursesPage: 1, lessonsPage: 1 },
      this.updateHistoryQueryParams,
    );
  };

  changeSortOrder = async ({ target: { value } }) => {
    const order = value;
    const { orderBy } = this.state;

    const sort = _.map(
      orderBy.split(','),
      item => `${order === 'desc' ? '-' : ''}${item}`,
    ).join(',');

    this.setState(
      { order, sort, coursesPage: 1, lessonsPage: 1 },
      this.updateHistoryQueryParams,
    );
  };

  afterCourseRemovedFromFavorite = () => {
    const { filter } = this.state;
    if (filter === COURSE_TYPES_FILTER_VALUES.MY_COURSES) {
      this.updateHistoryQueryParams();
    }
  };

  toggleDashboardExpander = () => {
    this.setState(
      prevState => ({
        isDashboardExpanded: !prevState.isDashboardExpanded,
      }),
      this.toggleDashboardCookie,
    );
  };

  isDashboardNotEmpty = () => {
    const {
      dashboardData,
      followedDiscussions,
      discussions,
      loadedDashboardData,
      loadedDiscussions,
      loadedFollowedDiscussions,
    } = this.props;

    const lastItem = _.get(dashboardData, 'courseWithTime');

    const isDashboardLoaded =
      loadedDashboardData && loadedDiscussions && loadedFollowedDiscussions;

    return (
      isDashboardLoaded &&
      ((discussions && discussions.length) ||
        (followedDiscussions && followedDiscussions.length) ||
        lastItem ||
        _.get(dashboardData, 'tasks') ||
        _.get(dashboardData, 'quizResult') ||
        _.get(dashboardData, 'examResult'))
    );
  };

  toggleDashboardCookie = () => {
    if (this.state.isDashboardExpanded === false) {
      this.props.cookies.remove(
        `${this.context.cookiePrefix}isDashboardShown`,
        {
          path: '/',
          domain: window.App.cookieDomain,
        },
      );
    } else if (this.isDashboardNotEmpty()) {
      this.props.cookies.set(
        `${this.context.cookiePrefix}isDashboardShown`,
        true,
        {
          path: '/',
          domain: window.App.cookieDomain,
        },
      );
    }
  };

  handleOpenNotificationPopover = () => {
    const { isNotificationsMenuOpened } = this.props;
    this.props.toggleNotificationsPopover(!isNotificationsMenuOpened);
  };

  updateHistoryQueryParams = (params = {}) => {
    const query = {
      ...this.getCurrentQueryParams(['perPage', 'page']),
      ...params,
    };
    if (_.get(params, 'listMode') === false) {
      delete query.listMode;
    }

    history.push({
      search: `?${buildQuery(query)}`,
    });
  };

  hasPristineState = () =>
    _.isEqual(this.getCurrentFiltersState(), this.initialState);

  getCurrentFiltersState = () => {
    const { sort, filter, contentLangId, type } = this.state;
    const { search, selectedContentCategories, selectedTags } = this.props;
    return {
      search: _.trim(search).length > 2 ? search : '',
      sort,
      filter,
      contentCategories: selectedContentCategories,
      tags: selectedTags,
      contentLangId,
      type,
    };
  };

  getAmountAppliedFilters = () => {
    if (this.hasPristineState()) {
      return 0;
    }

    const currentFilters = this.getCurrentFiltersState();

    let amountApplied = 0;
    Object.keys(this.initialState).forEach(key => {
      if (_.isArray(currentFilters[key])) {
        amountApplied +=
          _.xor(this.initialState[key], currentFilters[key])?.length || 0;
      } else if (this.initialState[key] !== currentFilters[key]) {
        amountApplied += 1;
      }
    });

    return amountApplied;
  };

  clearFilter = event => {
    const { auth } = this.props;

    if (this.hasPristineState()) {
      return;
    }

    event.preventDefault();

    this.handleFiltersMobileExpanded();
    this.setState(
      {
        ...this.initialState,
        ...parseSort(this.initialState.sort),
      },
      () => {
        Promise.all([
          this.props.dispatch(searchActions.changeSearchField('')),
          this.props.dispatch(searchActions.changeSearchSession('', '')),
          this.props.dispatch(searchActions.changePendingTagsField([])),
          this.props.dispatch(searchActions.changeTagsField([])),
          this.props.dispatch(
            searchActions.changePendingContentCategoriesField(
              getContentCategoriesForFilter(auth) || [],
            ),
          ),
          this.props.dispatch(
            searchActions.changeContentCategoriesField(
              getContentCategoriesForFilter(auth) || [],
            ),
          ),
        ]).then(() => {
          this.updateHistoryQueryParamsDebounced();
        });
      },
    );
  };

  handleToggleTag = value => () => {
    const { pendingTags } = this.props;
    const currentIndex = pendingTags.indexOf(value);
    const newTags = [...pendingTags];

    if (currentIndex === -1) {
      newTags.push(value);
    } else {
      newTags.splice(currentIndex, 1);
    }

    this.props.changePendingTagsField(newTags);
  };

  toggleSelectAllTags = () => {
    const { tags, pendingTags, changePendingTagsField } = this.props;
    if (pendingTags.length) {
      this.props.changePendingTagsField([]);
      return;
    }

    changePendingTagsField(tags.map(item => item._id));
  };

  handleToggleContentCategory = value => () => {
    const { pendingContentCategories } = this.props;

    const currentIndex = pendingContentCategories.indexOf(value);
    const newContentCategories = [...pendingContentCategories];

    if (currentIndex === -1) {
      newContentCategories.push(value);
    } else {
      newContentCategories.splice(currentIndex, 1);
    }

    this.props.changePendingContentCategoriesField(newContentCategories);
  };

  toggleSelectAllContentCategories = () => {
    const {
      contentCategories,
      pendingContentCategories,
      changePendingContentCategoriesField,
    } = this.props;
    if (pendingContentCategories.length) {
      this.props.changePendingContentCategoriesField([]);
      return;
    }

    changePendingContentCategoriesField(
      contentCategories.map(item => item._id),
    );
  };

  handleExpandTagsIndex = () => {
    this.setState({
      isTagsIndexExpanded: true,
    });
    this.props.changePendingTagsField(this.props.selectedTags);
  };

  handleExpandIndex = () => {
    this.setState({
      isIndexExpanded: true,
    });
    this.props.changePendingContentCategoriesField(
      this.props.selectedContentCategories,
    );
  };

  handleCollapseTagsIndex = () => {
    const {
      changeTagsField,
      pendingTags,
      selectedTags,
      changeSearchSession,
    } = this.props;
    const isTagsChanged = !_.isEqual(
      _.sortBy(pendingTags),
      _.sortBy(selectedTags),
    );

    let { lessonsPage, coursesPage } = this.state;

    if (isTagsChanged) {
      lessonsPage = 1;
      coursesPage = 1;
    }
    this.setState(
      { isTagsIndexExpanded: false, lessonsPage, coursesPage },
      () => {
        changeTagsField(pendingTags);
        changeSearchSession('', '');
      },
    );
  };

  handleCollapseIndex = () => {
    const {
      changeContentCategoriesField,
      pendingContentCategories,
      selectedContentCategories,
      changeSearchSession,
    } = this.props;
    const isContentCategoriesChanged = !_.isEqual(
      _.sortBy(pendingContentCategories),
      _.sortBy(selectedContentCategories),
    );

    let { lessonsPage, coursesPage } = this.state;

    if (isContentCategoriesChanged) {
      lessonsPage = 1;
      coursesPage = 1;
    }
    this.setState({ isIndexExpanded: false, lessonsPage, coursesPage }, () => {
      changeContentCategoriesField(pendingContentCategories);
      changeSearchSession('', '');
    });
  };

  handleFiltersMobileExpanded = () => {
    this.setState(prevState => ({
      isFiltersMobileExpanded: !prevState.isFiltersMobileExpanded,
    }));
  };

  render() {
    const {
      loadingCourses,
      loadedCourses,
      courses,
      loadingLessons,
      loadedLessons,
      lessons,
      dashboardData,
      notifications,
      auth,
      followedDiscussions,
      discussions,
      intl: { formatMessage },
      isTourOpen,
      toggleTour,
      totalCourses,
      totalLessons,
      loadingDashboardData,
      loadedDashboardData,
      loadedDiscussions,
      loadedFollowedDiscussions,
      loadingDiscussions,
      loadingFollowedDiscussions,
      search,
      pendingContentCategories,
      contentCategories,
      loadingContentCategories,
      isLoadedContentCategories,
      searchSessionId,
      pendingTags,
      tags,
      loadingTags,
      isLoadedTags,
      wereTagsEmpty,
    } = this.props;

    const {
      filter,
      order,
      orderBy,
      isIndexExpanded,
      isFiltersMobileExpanded,
      listMode: displayItemsInColumn,
      organizationCourses,
      contentLangId,
      type,
      isTagsIndexExpanded,
    } = this.state;

    const lastItem =
      (organizationCourses.length === 1 &&
        _.get(dashboardData, 'courseWithTime._id') !==
          _.get(organizationCourses[0], '_id') &&
        _.get(dashboardData, 'courseWithTime')) ||
      (organizationCourses.length > 1 &&
        _.get(dashboardData, 'courseWithTime'));
    const newUserWithoutCourse =
      _.get(auth, 'user.loginNumber', 0) <= 1 &&
      !lastItem &&
      (_.isEmpty(discussions) || _.isEmpty(followedDiscussions));

    const { isDashboardExpanded } = this.state;

    const unreadNotifications = _.filter(
      notifications,
      ({ readDate }) => !readDate,
    ).length;

    const filledSearch = _.trim(search).length > 2;

    const isDashboardLoaded =
      loadedDashboardData && loadedDiscussions && loadedFollowedDiscussions;

    const isDashboardNotEmpty = this.isDashboardNotEmpty();

    return (
      <main className={s.root}>
        <div className={s.container}>
          {isLoggedIn(auth) && (
            <section
              className={classNames(
                newUserWithoutCourse ? s.newUserDashboard : s.dashboard,
                isDashboardExpanded ? s.expanded : '',
                _.isEmpty(followedDiscussions) && _.isEmpty(discussions)
                  ? s.shrink
                  : '',
              )}
              id="dashboard"
            >
              {isDashboardExpanded &&
                loadedDashboardData &&
                loadedDiscussions &&
                loadedFollowedDiscussions &&
                (!_.isEmpty(followedDiscussions) || !_.isEmpty(discussions) ? (
                  <Slider
                    {...this.sliderSettings}
                    nextArrow={
                      <Arrow
                        customClassName={s.nextArrow}
                        Component={SkholeIcon40}
                      />
                    }
                    prevArrow={
                      <Arrow
                        customClassName={s.prevArrow}
                        Component={SkholeIcon41}
                      />
                    }
                    className={classNames(
                      s.dashboardContent,
                      s.notDisplayOnDekstopAndTablet,
                    )}
                  >
                    {dashboardData && (
                      <ContinueLearning
                        classNames={s}
                        formatMessage={formatMessage}
                        course={lastItem}
                        tasks={_.get(dashboardData, 'tasks')}
                        exam={_.get(dashboardData, 'examResult')}
                        quiz={dashboardData && dashboardData.quizResult}
                        unreadNotifications={unreadNotifications}
                        user={auth.user}
                        afterCourseRemovedFromFavorite={
                          this.afterCourseRemovedFromFavorite
                        }
                        handleOpenNotificationPopover={
                          this.handleOpenNotificationPopover
                        }
                      />
                    )}
                    {(discussions || followedDiscussions) && !isTrial(auth) && (
                      <DashboardDiscussions
                        followedDiscussions={followedDiscussions}
                        discussions={discussions}
                      />
                    )}
                  </Slider>
                ) : (
                  <div
                    className={classNames(
                      s.dashboardContent,
                      s.notDisplayOnDekstopAndTablet,
                      _.isEmpty(followedDiscussions) && _.isEmpty(discussions)
                        ? s.shrink
                        : '',
                      { [s.empty]: isDashboardLoaded && !isDashboardNotEmpty },
                    )}
                  >
                    {isDashboardLoaded && !isDashboardNotEmpty && (
                      <FormattedMessage {...messages.emptyDashboard} />
                    )}
                    {isDashboardLoaded &&
                      isDashboardNotEmpty &&
                      dashboardData && (
                        <ContinueLearning
                          classNames={classNames(
                            s.notDisplayOnDekstopAndTablet,
                          )}
                          formatMessage={formatMessage}
                          course={lastItem}
                          tasks={_.get(dashboardData, 'tasks')}
                          exam={_.get(dashboardData, 'examResult')}
                          quiz={_.get(dashboardData, 'quizResult')}
                          unreadNotifications={unreadNotifications}
                          user={auth.user}
                          afterCourseRemovedFromFavorite={
                            this.afterCourseRemovedFromFavorite
                          }
                          handleOpenNotificationPopover={
                            this.handleOpenNotificationPopover
                          }
                        />
                      )}
                  </div>
                ))}

              {isDashboardExpanded && (
                <div
                  className={classNames(
                    s.dashboardContent,
                    s.notDisplayOnMobile,
                    _.isEmpty(followedDiscussions) && _.isEmpty(discussions)
                      ? s.shrink
                      : '',
                    { [s.empty]: isDashboardLoaded && !isDashboardNotEmpty },
                  )}
                >
                  {isDashboardLoaded && !isDashboardNotEmpty && (
                    <FormattedMessage {...messages.emptyDashboard} />
                  )}
                  {isDashboardLoaded &&
                    isDashboardNotEmpty &&
                    dashboardData && (
                      <ContinueLearning
                        classNames={s}
                        formatMessage={formatMessage}
                        course={lastItem}
                        tasks={_.get(dashboardData, 'tasks')}
                        exam={_.get(dashboardData, 'examResult')}
                        quiz={_.get(dashboardData, 'quizResult')}
                        unreadNotifications={unreadNotifications}
                        user={auth.user}
                        afterCourseRemovedFromFavorite={
                          this.afterCourseRemovedFromFavorite
                        }
                        handleOpenNotificationPopover={
                          this.handleOpenNotificationPopover
                        }
                        inRow={
                          _.isEmpty(discussions) &&
                          _.isEmpty(followedDiscussions)
                        }
                      />
                    )}
                  {(discussions || followedDiscussions) && !isTrial(auth) && (
                    <DashboardDiscussions
                      followedDiscussions={followedDiscussions}
                      discussions={discussions}
                    />
                  )}
                </div>
              )}

              <div className={classNames(s.dashboardExpanderWrapper)}>
                {isDashboardLoaded && (
                  <Button
                    className={s.button}
                    onClick={this.toggleDashboardExpander}
                    id="toggle-dashboard"
                    disabled={
                      loadingDashboardData ||
                      loadingDiscussions ||
                      loadingFollowedDiscussions
                    }
                  >
                    {isDashboardExpanded ? (
                      <FormattedMessage {...messages.hideDashboardBtnLabel} />
                    ) : (
                      <FormattedMessage {...messages.showDashboardBtnLabel} />
                    )}
                    <ExpandMore className={s.dashboardExpanderIcon} />
                  </Button>
                )}
              </div>
              {(loadingDashboardData ||
                loadingDiscussions ||
                loadingFollowedDiscussions) && (
                <LinearProgress className={s.dashboardProgress} />
              )}
            </section>
          )}
          <Filter
            classNames={s}
            filter={filter}
            onFilterChange={this.onFilterChange}
            onChangeSort={this.changeSort}
            onChangeSortOrder={this.changeSortOrder}
            sortValue={orderBy}
            sortOrderValue={order}
            search={search}
            clearFilter={this.clearFilter}
            hasPristineState={this.hasPristineState()}
            auth={auth}
            categories={contentCategories}
            pendingCategories={pendingContentCategories}
            handleExpandIndex={this.handleExpandIndex}
            handleCollapseIndex={this.handleCollapseIndex}
            isIndexExpanded={isIndexExpanded}
            loadingCourses={loadingCourses}
            loadingContentCategories={loadingContentCategories}
            isLoadedContentCategories={isLoadedContentCategories}
            toggleSelectAllContentCategories={
              this.toggleSelectAllContentCategories
            }
            handleToggleContentCategory={this.handleToggleContentCategory}
            isFiltersMobileExpanded={isFiltersMobileExpanded}
            handleFiltersMobileExpanded={this.handleFiltersMobileExpanded}
            setIsColumnDisplayMode={this.setIsColumnDisplayMode}
            displayItemsInColumn={displayItemsInColumn}
            onContentLanguageChange={this.onContentLanguageChange}
            contentLangId={contentLangId}
            type={type}
            onContentTypeChange={this.onContentTypeChange}
            amountApplied={this.getAmountAppliedFilters()}
            tags={tags}
            pendingTags={pendingTags}
            loadingTags={loadingTags}
            isLoadedTags={isLoadedTags}
            toggleSelectAllTags={this.toggleSelectAllTags}
            handleToggleTag={this.handleToggleTag}
            isTagsIndexExpanded={isTagsIndexExpanded}
            handleCollapseTagsIndex={this.handleCollapseTagsIndex}
            handleExpandTagsIndex={this.handleExpandTagsIndex}
            wereTagsEmpty={wereTagsEmpty}
          />
          <section
            className={classNames(
              s.listWrapper,
              filledSearch && loadedCourses && !totalCourses
                ? s.hidden
                : undefined,
            )}
            id="courses-list"
          >
            {filledSearch && loadedCourses && (
              <>
                <hr className={s.searchResultsHr} />
                <Typography
                  className={s.searchResultsTitle}
                  component="h2"
                  variant="h6"
                  gutterBottom
                >
                  <FormattedMessage {...messages.courses} />
                  <span className={s.searchResultsCount}>
                    <FormattedMessage
                      {...messages.searchCoursesResults}
                      values={{
                        total: totalCourses > 50 ? `50+` : totalCourses,
                      }}
                    />
                  </span>
                </Typography>
              </>
            )}
            {loadingCourses && (
              <CircularProgressLoader rootClassName={s.loader} />
            )}
            <Grid
              className={classNames(s.gridList, {
                [s.columnGridList]: displayItemsInColumn,
              })}
              spacing={2}
              container
              direction={displayItemsInColumn ? 'column' : 'row'}
              alignItems={displayItemsInColumn ? 'center' : 'flex-start'}
            >
              {loadedCourses && _.isEmpty(courses) && (
                <div className={s.emptyCourseLabel}>
                  {formatMessage(messages.noCoursesHereLabel)}
                </div>
              )}
              {courses.map(course => (
                <Grid
                  key={course._id}
                  xs={12}
                  sm={displayItemsInColumn ? 12 : 6}
                  md={displayItemsInColumn ? 12 : 4}
                  lg={displayItemsInColumn ? 12 : 3}
                  xl={displayItemsInColumn ? 12 : 2}
                  className={classNames(s.courseListItem, {
                    [s.itemsInOneColumn]: displayItemsInColumn,
                  })}
                  item
                >
                  <CourseItem
                    item={course}
                    page={this.props.page}
                    courses={courses.length}
                    afterCourseRemovedFromFavorite={
                      this.afterCourseRemovedFromFavorite
                    }
                    inOneColumn={displayItemsInColumn}
                    key={course._id + displayItemsInColumn}
                    searchSessionId={searchSessionId}
                  />
                </Grid>
              ))}
            </Grid>
          </section>
          {this.hasMoreCourses() && loadedCourses && (
            <div className={s.loadMoreButtonWrapper}>
              <Button
                variant="outlined"
                color="primary"
                disabled={loadingCourses}
                onKeyDown={this.loadMoreCourses}
                onMouseDown={this.loadMoreCourses}
                size="large"
                id="load-more-courses"
              >
                {loadingCourses ? (
                  <FormattedMessage {...messages.loadingBtnLabel} />
                ) : (
                  <FormattedMessage {...messages.loadMoreBtnLabel} />
                )}
                <ExpandMore />
              </Button>
              {loadingCourses && (
                <CircularProgressSmall
                  size={30}
                  wrapperClassName={s.buttonProgress}
                />
              )}
            </div>
          )}
          {filledSearch && loadedLessons && (
            <div
              className={classNames(
                s.listWrapper,
                filledSearch && loadedLessons && !totalLessons
                  ? s.hidden
                  : undefined,
              )}
              id="lessons-list"
            >
              <hr className={s.searchResultsHr} />
              <Typography
                className={s.searchResultsTitle}
                component="h2"
                variant="h6"
                gutterBottom
              >
                <FormattedMessage {...messages.lessons} />
                <span className={s.searchResultsCount}>
                  <FormattedMessage
                    {...messages.searchLessonsResults}
                    values={{
                      total: totalLessons > 50 ? `50+` : totalLessons,
                    }}
                  />
                </span>
              </Typography>
              {loadingLessons && (
                <CircularProgressLoader rootClassName={s.loader} />
              )}
              <Grid
                className={classNames(s.gridList, {
                  [s.columnGridList]: displayItemsInColumn,
                })}
                spacing={2}
                container
                direction={displayItemsInColumn ? 'column' : 'row'}
                alignItems={displayItemsInColumn ? 'center' : 'flex-start'}
              >
                {loadedLessons && _.isEmpty(lessons) && (
                  <div className={s.emptyLessonLabel}>
                    {formatMessage(messages.noCoursesHereLabel)}
                  </div>
                )}
                {lessons.map(lesson => (
                  <Grid
                    key={lesson._id}
                    xs={12}
                    sm={displayItemsInColumn ? 12 : 6}
                    md={displayItemsInColumn ? 12 : 4}
                    lg={displayItemsInColumn ? 12 : 3}
                    xl={displayItemsInColumn ? 12 : 2}
                    className={classNames(s.lessonsListItem, {
                      [s.itemsInOneColumn]: displayItemsInColumn,
                    })}
                    item
                  >
                    <LessonItem
                      item={lesson}
                      inOneColumn={displayItemsInColumn}
                      key={lesson._id + displayItemsInColumn}
                      searchSessionId={searchSessionId}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
          {filledSearch &&
            loadedLessons &&
            loadedCourses &&
            !totalLessons &&
            !totalCourses && (
              <div className={s.listWrapper}>
                <div className={s.emptyResultsLabel}>
                  {formatMessage(messages.noResultsHere)}
                </div>
              </div>
            )}
          {filledSearch && loadedLessons && this.hasMoreLessons() && (
            <div className={s.loadMoreButtonWrapper}>
              <Button
                variant="outlined"
                color="primary"
                disabled={loadingLessons}
                onClick={this.loadMoreLessons}
                size="large"
              >
                {loadingLessons ? (
                  <FormattedMessage {...messages.loadingBtnLabel} />
                ) : (
                  <FormattedMessage {...messages.loadMoreBtnLabel} />
                )}
                <ExpandMore />
              </Button>
              {loadingLessons && (
                <CircularProgressSmall
                  size={30}
                  wrapperClassName={s.buttonProgress}
                />
              )}
            </div>
          )}
          {isEditor(auth) && (
            <div className={s.addBtnWrapper}>
              <Tooltip
                title={formatMessage(messages.addNewCourseTitle)}
                enterDelay={300}
              >
                <Fab
                  color="primary"
                  aria-label="add"
                  component={Link}
                  to="/edit/courses/new"
                  className={s.addBtn}
                  data-test="add-course-btn"
                >
                  <SkholeIcon17BiggerSize
                    className={s.addIcon}
                    color="#fff"
                    viewBox=" 0 0 28 28"
                  />
                </Fab>
              </Tooltip>
            </div>
          )}
        </div>
        <Tour
          isOpen={isTourOpen}
          onRequestClose={() => toggleTour('courses', false)}
          update={`${isDashboardExpanded}`}
          steps={[
            {
              selector: '#site-search-block, #site-search-categories',
              content: formatMessage(tourMessages.search),
            },
            !isTrial(auth)
              ? {
                  selector: '#navigation-menu-btn',
                  content: formatMessage(tourMessages.groupMenu),
                }
              : undefined,
            {
              selector: '#navigation-help-btn',
              content: formatMessage(tourMessages.help),
            },
            {
              selector: '#navigation-notifications-btn',
              content: formatMessage(tourMessages.notifications),
            },
            {
              selector: '#navigation-profile-btn',
              content: formatMessage(tourMessages.profile),
            },
            {
              selector: isDashboardExpanded
                ? '#dashboard'
                : '#toggle-dashboard',
              content: formatMessage(tourMessages.dashboard),
            },
            {
              selector: '#courses-list',
              content: formatMessage(tourMessages.coursesList),
            },
          ].filter(step => step !== undefined)}
        />
      </main>
    );
  }
}
