import React from 'react';
import _ from 'lodash';
import { defineMessages } from 'react-intl';
import { loadFavoriteCourses } from '../../actions/auth';
import { getCourses, reset } from '../../actions/courses';
import { getLessons, resetLessons } from '../../actions/homeLessons';
import {
  changeSearchField,
  changeSearchSession,
  changeContentCategoriesField,
  changeTagsField,
} from '../../actions/search';
import { getContentCategories } from '../../actions/contentCategories';
import { getTags } from '../../actions/tags';
import Layout from '../../components/Layout';
import { getContentCategoriesForFilter, ssrRequest } from '../../helpers';
import isLoggedIn from '../../helpers/checkAuth';
import Courses from './Courses';
import initialState from './intialState';

const messages = defineMessages({
  title: {
    id: 'Courses.title',
    defaultMessage: 'Healthcare E-learning',
  },
  description: {
    id: 'Courses.description',
    defaultMessage:
      'Skhole creates the new universal standard for healthcare education. The best online learning from Finland to serve the whole world.',
  },
});

async function action(context) {
  const {
    store,
    fetch,
    intl: { formatMessage },
    query,
    serverUrl,
    organizationSlug,
    whitelabeling,
    cloudfrontUrl,
    contentLangs,
  } = context;
  const {
    auth,
    intl: { locale },
  } = store.getState();

  const promises = [];
  const perPage = 32;
  const page = query.page || 1;

  const { isInitialContentCategoriesLoad } = store.getState().search;

  let updatedContentCategories =
    query.contentCategories || initialState.contentCategories;

  if (isInitialContentCategoriesLoad && !updatedContentCategories?.length) {
    updatedContentCategories = getContentCategoriesForFilter(auth);
  }

  promises.push(() =>
    store.dispatch(
      changeContentCategoriesField(updatedContentCategories || []),
    ),
  );

  promises.push(() =>
    store.dispatch(changeTagsField(query.tags || initialState.tags)),
  );

  promises.push(() =>
    store.dispatch(changeSearchField(query.search || initialState.search)),
  );

  let contentLangId = _.isString(query.contentLangId)
    ? query.contentLangId
    : '';

  if (!contentLangId) {
    contentLangId = contentLangs?.find(item => item.lang === locale)?._id;
  }

  const search = _.isString(query.search) ? query.search : initialState.search;

  if (contentLangId === 'all' || search) {
    contentLangId = '';
  }

  let sort = _.isString(query.sort) ? query.sort : initialState.sort;

  if (search) {
    sort = '';
  }

  promises.push(() => {
    const q = {
      page,
      perPage,
      sort,
      contentLangId,
      type: _.isString(query.type) ? query.type : initialState.type,
      filter: _.isString(query.filter) ? query.filter : initialState.filter,
      contentCategories:
        _.isArray(updatedContentCategories) && !search
          ? updatedContentCategories
          : [],
      search,
      tags: _.isArray(query.tags) && !search ? query.tags : [],
      includeNotAvailable: true,
    };

    return ssrRequest({
      getAction: getCourses.bind(this, q),
      resetAction: reset,
      store,
      fetch,
      context,
    });
  });

  promises.push(() => {
    if (_.trim(query.search).length < 3) {
      return Promise.resolve();
    }

    const q = {
      page: 1,
      perPage,
      sort: _.isString(query.sort) ? query.sort : initialState.sort,
      contentLangId,
      tags: _.isArray(query.tags) ? query.tags : initialState.tags,
      search: _.isString(query.search) ? query.search : initialState.search,
      isHomePage: true,
      withoutPopulation: true,
      includeNotAvailable: true,
    };

    return ssrRequest({
      getAction: getLessons.bind(this, q),
      resetAction: resetLessons,
      store,
      fetch,
      context,
    });
  });

  if (isLoggedIn(auth)) {
    promises.push(async () => store.dispatch(loadFavoriteCourses(fetch)));

    promises.push(async () =>
      ssrRequest({
        getAction: getContentCategories.bind(this, {
          page: 1,
          perPage: 50,
          sort: `name.${locale}`,
          search: '',
          isGeneral: false,
        }),
        store,
        fetch,
        context,
      }),
    );
  } else {
    promises.push(async () =>
      ssrRequest({
        getAction: getTags.bind(this, {
          page: 1,
          perPage: 50,
          sort: 'name',
          contentLangId,
          isHomePageSearch: true,
        }),
        store,
        fetch,
        context,
      }),
    );
  }

  await Promise.all(promises.map(promise => promise()));

  let title = formatMessage(messages.title);
  let description = formatMessage(messages.description);
  let imageUrl;

  if (organizationSlug) {
    const customDescription = _.get(
      whitelabeling,
      `serviceDescription.${locale}`,
    );
    const customTitle = _.get(whitelabeling, `indexTitle.${locale}`);
    const ogImage = _.get(whitelabeling, `ogImage`);

    if (title) {
      title = customTitle;
    }

    if (customDescription) {
      description = customDescription;
    }

    if (ogImage) {
      imageUrl = `${cloudfrontUrl}${ogImage}`;
    }
  }

  let prevUrl;
  let nextUrl;
  const canonicalUrl = `${serverUrl}${
    page > 1
      ? `/${locale}${
          organizationSlug ? `/${organizationSlug}` : ''
        }?page=${page}`
      : ''
  }`;

  const {
    courses: { total },
    tags: { tags },
    homeLessons,
    search: { searchSession },
    contentCategories: { contentCategories },
  } = store.getState();

  if (page > 1 && page <= Math.ceil(total / perPage)) {
    prevUrl = `${serverUrl}${
      page - 1 > 1
        ? `/${locale}${
            organizationSlug ? `/${organizationSlug}` : ''
          }?page=${page - 1}`
        : ''
    }`;
  }

  if (page < Math.ceil(total / perPage)) {
    nextUrl = `${serverUrl}/${locale}${
      organizationSlug ? `/${organizationSlug}` : ''
    }?page=${page + 1}`;
  }

  const keywords = [
    ..._.map(tags || [], 'name'),
    ..._.map(contentCategories || [], `name.${locale}`),
  ];

  let listMode = false;

  if (query.listMode || _.get(auth, 'user.listMode')) {
    listMode = true;
  }

  if (
    isLoggedIn(auth) &&
    _.trim(query.search).length >= 3 &&
    !searchSession._id
  ) {
    try {
      const newSearchSessionRes = await fetch('/api/courses/search-session', {
        method: 'POST',
        body: JSON.stringify({
          searchPhrase: query.search,
          foundCoursesAmount: total,
          foundLessonsAmount: homeLessons.total,
          tags: [],
        }),
      });

      if (newSearchSessionRes.status === 201) {
        const newSearchSession = await newSearchSessionRes.json();
        store.dispatch(changeSearchSession(newSearchSession._id, query.search));
      }
    } catch (err) {
      console.error(err);
    }
  }

  return {
    chunks: ['home'],
    title,
    keywords,
    description,
    prevUrl,
    canonicalUrl,
    nextUrl,
    imageUrl,
    component: (
      <Layout context={context} tour="courses">
        <Courses
          data={{}}
          title={title}
          page={page}
          query={query}
          listMode={listMode}
        />
      </Layout>
    ),
  };
}

export default action;
